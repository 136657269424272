import 'slick-carousel/slick/slick';

export default {
  init() {
    // JavaScript to be fired on all pages


    $('#product-cards-slider').slick({
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
    });

    // Ezproducts Card Slider
    $('.ezproducts-slider').slick({
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            // centerMode: true,
            slidesToShow: 1,
            infinite: true,
          },
        },
        // {
        //   breakpoint: 640,
        //   settings: {
        //     slidesToShow: 2,
        //   },
        // },
      ],
    });
    console.log('COMMON>JS');

    // Collapse ////////////////////////////////////////////////////////
    // Initialized collapse elements
    setTimeout(() => {
      $('.collapse').each(function () {
        var elm = $(this);

        if (
          elm.hasClass('show') &&
          elm.prev('.collapse-toggle').hasClass('active')
        ) {
          elm.show();
        }
      });
    }, 500);

    // When collapse toggle is clicked
    $('body').on('click', '.collapse-toggle', function () {
      var toggle = $(this);
      var group = toggle.data('group');
      var target = $(toggle.data('target'));
      var limit = toggle.data('limit');

      if (limit === 'any') {
        target.slideToggle(400);
        toggle.toggleClass('active');
      } else {
        if (toggle.hasClass('active')) {
          toggle.removeClass('active');
          target.slideUp(400);
        } else {
          $(group + '.collapse-toggle').removeClass('active');
          toggle.addClass('active');
          $(group + '.collapse').slideUp(400);
          target.slideDown(400);
        }
      }

      // var slickTarget = toggle.data('slick');
      // if (slickTarget !== undefined) {
      //   var slideIndex = toggle.data('goto');
      //   $(slickTarget).slick('slickGoTo', parseInt(slideIndex));
      // }

      var autoscroll = toggle.data('autoscroll');

      if (autoscroll) {
        setTimeout(() => {
          $('html,body').animate(
            {
              scrollTop: toggle.offset().top,
            },
            1000
          );
        }, 500);
      }
    });
    // Collapse //////////////////////////////////////////////////////// END

    // Toggle mobile menu panel - Start
    // Open panel
    $('#mobile-menu-toggle').on('click', function () {
      $('#mobile-menu-panel').addClass('active');
    });

    // Close panel
    $('#close-mobile-menu-panel').on('click', function () {
      $('#mobile-menu-panel').removeClass('active');
      setTimeout(() => {
        $('.nav-mobile .menu-item-has-children').removeClass('active');
        $('.nav-mobile .menu-item-has-children .sub-menu').addClass('close');
        $('.nav-mobile .menu-item-has-children .sub-menu').slideUp(400);
      }, 300);
    });
    // Toggle mobile menu panel - End

    // Mobile Nav Collapse - Start
    var idx = 1;

    $('.nav-mobile .menu-item-has-children').each(function () {
      // console.log('each', 'processing...');
      var elm = $(this);

      // Setting up collapse
      elm.find('.sub-menu').attr('id', 'mobile-submenu-' + idx);
      elm.find('.sub-menu').addClass('mobile-submenu collapse close');
      elm.find('.sub-menu').slideUp(400);

      // Setting up toggle
      elm.attr('data-target', '#mobile-submenu-' + idx);
      elm.attr('data-group', '.mobile-submenu');
      elm.attr('data-limit', 'any');
      elm.addClass(
        'collapse-toggle-style-mobilenav collapse-toggle mobile-submenu'
      );

      idx++;
    });
    // Mobile Nav Collapse - End

    // Home - Cards Slider - Start
    $('#product-cards .product-card h3').each(function() {
      let txt = $(this).html().slice(2);

      console.log('txt', txt);

      $(this).html('<div class="ez inline text-xl xl:text-32px">ez</div>' + txt);
    });

    $('#product-cards .product-card').on('mouseover', function(){
      $('.product-card').addClass('active');
      $('.product-card').removeClass('hover');
      $(this).removeClass('active');
      $(this).addClass('hover');
    });

    $('#product-cards .product-card').on('mouseout', function(){
      $('.product-card').removeClass('active');
      $('.product-card').removeClass('hover');

      $('.product-card').addClass('active');
      $('.product-card.card-0').removeClass('active');
      $('.product-card.card-0').addClass('hover');
    });
    // Home - Cards Slider - End

    // Contact Form
    var leadgen = $('#leadgen');
    // alert(leadgen.find('input[name="source"]').val('lksfjl'));

    if (sessionStorage.getItem('source')) {
      leadgen
        .find('input[name="source"]')
        .val(sessionStorage.getItem('source'));
    }

    $('#leadgen').submit(function (e) {
      e.preventDefault();

      var formdata = leadgen.serialize();
      var prod = $(this).find('input[name="product"]').val();
      // console.log(prod);

      $.ajax({
        type: 'POST',
        url: leadgen.attr('action'),
        data: formdata,
        dataType: 'json',
        beforeSend: function () {
          showLoader('.leadgen_body');
        },
      })
        .done(function (data) {
          console.log(data);
          $('#leadgen').hide();
          $('#leadgenSuccess').show();
          // eslint-disable-next-line no-undef
          ga('send', 'event', 'Website Leads', 'Submit Form', prod + ' Leads');
          setTimeout(function () {
            $('.leadgen_body .loading').fadeOut();
            $('.leadgen_body').html(data.message);
            $('.leadgenform h2').text('Thank You.');
            $('.leadgenform__description').remove();
          }, 1000);
        })
        .error(function (data) {
          setTimeout(function () {
            $('.leadgen_body .loading').fadeOut();
            var error = data.responseJSON;
            // console.log(error);
            $.each(error, function (index, value) {
              var message = '';
              for (var i = 0; i < value.length; i++) {
                message += value[i];
              }
              $('.ginput_container_' + index).append(
                '<div class="error"><small class="error">' +
                  message +
                  '</small></div>'
              );
              $('.leadgen_' + index).addClass('hasError');
            });
          }, 1000);
        });
    });

    leadgen.find('input').focus(function () {
      $(this).removeClass('hasError');
      $(this).parent().find('.error').remove();
    });

    function showLoader(element) {
      $(element).append('<div class="loading"><div class="inner"></div></div>');
    }

    var displayPromo = false;
    // var superAdminview = false;
    var promo = JSON.parse(sessionStorage.getItem('promo'));
    if (promo) {
      for (var i = 0; i < promo.length; i++) {
        if (promo[i] === 'all') {
          $('.promo-banner').css('display', 'block');
          return;
        }
      }

      $('.promo-banner').each(function () {
        if (displayPromo === true) {
          return;
        }
        for (var i = 0; i < promo.length; i++) {
          if ($(this).attr('id') === promo[i]) {
            $(this).css('display', 'block');
            displayPromo = true;
            return;
          }
        }
      });
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
